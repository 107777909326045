.quick-user{
    background: #F4F6F9;
    border-bottom: solid 2px #096bef;
    padding: 0 10px 10px 18px;
}
.quick-user .quick-user_head{
    color: #344357;
    font-size: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 14px;
}
.quick-user .quick-user_title{
    color: #344357;
    font-size: 18px;
    font-weight: 500;
    align-items: center;
}
.quick-user .quick-info span{
  font-size: 14px;
  color: #8091A7;
}
.quick-user .quick-info p{
    margin: 0;
}
.quick-user .user-name{     
    color: #1F2B3A;
    font-weight: 500 ;    
}