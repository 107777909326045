/* .app-header{
  background: #fff;
  min-height: 60px;
  width: 100%;
  border: solid 1px red;  
} */
.applogo{
    padding-right: 10px;
    border-right:solid 1px #A6C1D3 ;
  }
  
  .use-profile{
    display: flex;
    align-items: center;
    border: solid 1px #a6c1d3;
    border-radius: 4px;
    padding: 6px 6px; 
    text-decoration: none; 
  }
  .use-profile .company-logo{
    max-width: 88px; 
    margin-right: 10px;   
  }
  .use-profile .company-logo img{
    max-width: 100%;
    width: auto;
    height: auto;
  }